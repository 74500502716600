import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import auimLogo from '../../assets/img/auim-logo-white.png'
import NavDrawer from "./NavDrawer";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    title: {
        flexGrow: 1,
    },
    img: {
        width: '200px',
        padding: '20px'
    },
    customAppBar: {
        backgroundColor: '#f39c1290'
    }
}));

export default function Navbar() {
    const classes = useStyles();
    const scrollToRef = (ref) => window.scrollTo(0, 200);


    return (
        <div className={classes.root}>


            <AppBar position="fixed" className={classes.customAppBar}>
                <Toolbar>
                    <NavDrawer />
                    <div className={classes.title}>
                        <img src={auimLogo} alt="auim-logo" className={classes.img}/>
                    </div>
                    <div id="nav-btns">
                    <Button color="inherit" href="#sobre-nos">Sobre nós</Button>
                    <Button color="inherit" href="#produtos">Produtos</Button>
                    <Button color="inherit" href="#servicos">Serviços</Button>
                    <Button color="inherit" href="#fale-conosco">Fale Conosco</Button>
                        </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}

