import React from "react";
import {makeStyles, Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import ProductCard from "../ProductCard";
import Grid from "@material-ui/core/Grid";
import auimCentral from '../../assets/img/auimcentral.jpg';
import smartAuim from '../../assets/img/smartAuim.jpg';

const products = [
    {
        img: auimCentral,
        title: 'AUIMCentral',
        description: 'Um produto adaptado para monitorar transporte de Vans Escolares, Ônibus de\n' +
            'Viagem ou transporte público e também corrida de aplicativo(s). O mesmo poderá\n' +
            'ser adaptado para múltiplas plataformas e assim a corrida fornecerá mais\n' +
            'conforto, segurança e confiabilidade para você e sua família. Já imaginou poder\n' +
            'monitorar sua família em tempo real de qualquer lugar e ainda mais, pode\n' +
            'solicitar o vídeo da corrida e assistir em casa, é uma boa ideia, não é?\n' +
            'Assim tudo ficará mais seguro, esse é o objetivo da AUIM, trazer tranquilidade,\n' +
            'credibilidade para o serviço prestado e o mais importante segurança a todos. ',
        action: () => {console.log('Clicou')}
    },
    {
        img: smartAuim,
        title: 'SmartAUIM',
        description: 'Este produto tem como objetivo principal auxiliar o atendimento remoto para pessoas\n' +
            'com algum tipo tratamento residencial, crônico ou até mesmo um cuidado com uma pessoa que ' +
            'necessite de atenção enviando os dados do paciente automaticamente para um centro de atendimento ' +
            'conveniado. Também conta com outros recursos de monitoramento.',
        action: () => {console.log('Clicou')}
    }
];


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '60px 0'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    title: {
        fontFamily: '\'Lexend Zetta\', sans-serif;',
        fontWeight: 'bold',
        marginBottom: '40px',
        color: '#f39c12'
    },
    img: {
        width: '200px',
        padding: '20px'
    },
    customAppBar: {
        backgroundColor: '#f39c12'
    }
}));


export default function Products() {

    const classes = useStyles();

    return(
        <div className={classes.root} id="produtos">
            <Container maxWidth="lg">
                <Typography variant="h3" align="center" className={classes.title}>
                    NOSSOS PRODUTOS
                </Typography>
                <Divider style={{marginBottom: '40px'}}/>
                <Grid container spacing={2}>
                    {products.map(item => {
                       return (
                           <Grid item md={6} sm={6} xs={12} key={item.title}>
                               <ProductCard img={item.img}
                                            title={item.title}
                                            description={item.description}
                                            action={item.action}
                               />
                           </Grid>
                       );
                    })}
                </Grid>
            </Container>
        </div>
    );
}
