import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DialogView from "../DialogView";

const useStyles = makeStyles({
    card: {

    },
    media: {
        height: 250,
        objectFit: 'cover'
    },
});

const minDescription = (d) => d.length <= 200 ? d : d.slice(0, 200) + '...';


export default function ProductCard(props) {
    const classes = useStyles();

    const { img, title, description, action } = props;

    return (
        <Card className={classes.card}>
            <CardActionArea>
                <CardMedia
                    className={classes.media}
                    image={img}
                    title={title}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {minDescription(description)}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <DialogView img={img}
                            title={title}
                            description={description}
                            action={action}
                />
            </CardActions>
        </Card>
    );
}
