import React, { Component } from 'react';
import Navbar from "../../components/Navbar";
import CustomCarousel from "../../components/Carousel";
import AboutUs from "../../components/AboutUs";
import Products from "../../components/Products";
import Services from "../../components/Services";
import ContactUs from "../../components/ContactUs";
import Footer from "../../components/Footer";

export default class Landing extends Component{
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Navbar />
                <div style={{width: '100%', height: '97px', backgroundColor: '#f39c12'}}/>
                <CustomCarousel />
                <AboutUs id="sobre-nos" />
                <Products />
                <Services />
                <ContactUs />
                <Footer />
            </>
        );
    }

}
