import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    card: {

    },
    media: {
        width: '100%',
        height: 400,
        objectFit: 'cover'
    },
});

export default function DialogView(props) {

    const classes = useStyles();

    const { img, title, description, info } = props;

    const [open, setOpen] = React.useState(false);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    return (
        <div>
            <Button size="small" color="primary" onClick={handleClickOpen}>
                Saiba mais
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <img src={img} className={classes.media} alt={title} />
                    <DialogContentText align="justify" style={{marginTop: '20px'}}>
                        {description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
