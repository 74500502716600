import React from "react";
import {makeStyles, Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import ProductCard from "../ProductCard";
import Grid from "@material-ui/core/Grid";
import automacao from '../../assets/img/example2.jpg';
import devApps from '../../assets/img/devApps.jpg';


const products = [
    {
        img: automacao,
        title: 'Automação Industrial e Residencial',
        description: 'Soluções de Automação sob medida à sua necessidade',
        action: () => {console.log('Clicou')}
    },
    {
        img: devApps,
        title: 'Desenvolvimento de Sistemas e APPs costumizáveis',
        description: 'Desenvolvemos Sistemas e APPs confome sua necessidade, buscando sempre confiabilidade, ' +
            'performance e qualidade!',
        action: () => {console.log('Clicou')}
    }
];


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '60px 0',
        backgroundColor: "#1ba39c"
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    title: {
        fontFamily: '\'Lexend Zetta\', sans-serif;',
        fontWeight: 'bold',
        marginBottom: '40px',
        color: '#ffffff'
    },
    img: {
        width: '200px',
        padding: '20px'
    },
    customAppBar: {
        backgroundColor: '#f39c12'
    }
}));



export default function Services() {

    const classes = useStyles();

    return(
        <div className={classes.root} id="servicos">
            <Container maxWidth="lg">
                <Typography variant="h3" align="center" className={classes.title}>
                    NOSSOS SERVIÇOS
                </Typography>
                <Divider style={{marginBottom: '40px'}}/>
                <Grid container spacing={2}>
                    {products.map(item => {
                        return (
                            <Grid item md={6} sm={6} xs={12} key={item.title}>
                                <ProductCard img={item.img}
                                             title={item.title}
                                             description={item.description}
                                             action={item.action}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Container>
        </div>
    );
}
