import React from "react";
import {makeStyles, Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '60px 0',
        backgroundColor: "#013243"
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    title: {
        fontFamily: '\'Lexend Zetta\', sans-serif;',
        fontWeight: 'bold',
        marginBottom: '40px',
        color: '#ffffff'
    },
    text: {
        marginTop: '40px',
        fontSize: '1.2rem'
    },
    img: {
        width: '200px',
        padding: '20px'
    },
    customAppBar: {
        backgroundColor: '#f39c12'
    }
}));


export default function Footer() {

    const classes = useStyles();

    return(
        <div className={classes.root}>
            <Container maxWidth="lg">
                <footer>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{color: '#aaaaaa'}}>
                                &copy; AUIM - Tecnologia e Automação - Todos os diretiros reservados
                            </Typography>
                        </Grid>
                    </Grid>
                </footer>
            </Container>
        </div>
    );
}
