import React from "react";
import {makeStyles, Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '60px 0',
        backgroundColor: "#f39c12"
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    title: {
        fontFamily: '\'Lexend Zetta\', sans-serif;',
        fontWeight: 'bold',
        marginBottom: '40px',
        color: '#ffffff'
    },
    text: {
        marginTop: '40px',
        fontSize: '1.2rem'
    },
    img: {
        width: '200px',
        padding: '20px'
    },
    customAppBar: {
        backgroundColor: '#f39c12'
    }
}));


export default function AboutUs() {

    const classes = useStyles();

    return(
        <div className={classes.root} id="sobre-nos">
            <Container maxWidth="lg">
                <Typography variant="h3" align="center" className={classes.title}>
                    SOBRE NÓS
                </Typography>
                <Divider />
                <Typography variant="body1" align="justify" className={classes.text}>
                    A AUIM - Tecnologia e Automação é uma Startup, uma empresa jovem com um modelo de negócios
                    repetível e escalável, em um cenário de incertezas e soluções a serem
                    desenvolvidas. Embora a AUIM não se limite apenas a negócios digitais, criamos
                    inovações para não ser considerada uma empresa de modelo tradicional. <br/>
                    De fato, a AUIM é uma startup que está na moda e empreender virou o sonho
                    de muita gente, tanto no Brasil quanto fora dele. Um erro comum que permeia a
                    definição de startups é se elas são somente empresas de internet. Não
                    necessariamente, elas só são mais frequentes na Internet porque é bem mais
                    barato e facilmente propagável criar uma empresa online do que uma de
                    métodos tradicionais, por exemplo. <br/>
                    Antes de tudo, o modelo de negócios da AUIM é diferente de um plano de
                    negócios, que foca em estratégias detalhadas para atingir metas, por exemplo. <br/>
                    No modelo de negócios da AUIM, o foco não é necessariamente no produto, mas
                    no valor e, consequentemente, na rentabilidade. Em outras palavras, como o seu
                    negócio soluciona a dor do cliente de forma lucrativa. <br/>
                    Muitas vezes, o desafio do modelo de negócios de startups é criar algo inovador:
                    ou adaptar um modelo de negócios para uma área onde não é comumente
                    aplicado, ou criar um modelo totalmente novo.
                </Typography>
            </Container>
        </div>
    );
}
