import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import slide1 from '../../assets/img/auim-banners-01.jpg'
import slide2 from '../../assets/img/auim-banners-02.jpg'


export default function CustomCarousel() {
    return (
        <Carousel showThumbs={false}
                  showStatus={false}
                  infiniteLoop={true}
                  autoPlay={true}
                  stopOnHover={false}
                  emulateTouch={true}
                  interval={3000}
        >
            <div>
                <img src={slide1}/>
                {/*<p className="legend">Legend 1</p>*/}
            </div>
            <div>
                <img src={slide2}/>
                {/*<p className="legend">Legend 2</p>*/}
            </div>
        </Carousel>
    );
}
