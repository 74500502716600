import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";


const useStyles = makeStyles({

    list: {
        width: 250,
        backgroundColor: '#002e42'
    },
    fullList: {
        width: 'auto'
    },
    fullButton: {
        flexGrow: 1,
        textDecoration: 'none',
        padding: '10px 5px',
        color: '#fff'
    },
    iconBtn: {
        marginRight: '10px',
        color: '#e2e2e2'
    }
});

export default function NavDrawer() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({...state, [side]: open});
    };

    const sideList = side => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
        >
            <List>
                <ListItem>
                    <Icon className={classes.iconBtn}>supervisor_account</Icon>
                    <a color="inherit" href="#sobre-nos" className={classes.fullButton}>Sobre nós</a>
                </ListItem>
                <Divider/>
                <ListItem>
                    <Icon className={classes.iconBtn}>emoji_objects</Icon>
                    <a color="inherit" href="#produtos" className={classes.fullButton}>Produtos</a>
                </ListItem>
                <Divider/>
                <ListItem>
                    <Icon className={classes.iconBtn}>public</Icon>
                    <a color="inherit" href="#servicos" className={classes.fullButton}>Serviços</a>
                </ListItem>
                <Divider/>
                <ListItem>
                    <Icon className={classes.iconBtn}>chat</Icon>
                    <a color="inherit" href="#fale-conosco" className={classes.fullButton}>Fale Conosco</a>
                </ListItem>
            </List>
            <Divider/>
        </div>
    );


    return (
        <div>
            <IconButton edge="start" color="inherit" aria-label="menu" id="nav-sandwich"
                        onClick={toggleDrawer('left', true)}>
                <Icon>menu</Icon>
            </IconButton>

            <Drawer open={state.left} className={classes.drawerStyle} onClose={toggleDrawer('left', false)}>
                {sideList('left')}
            </Drawer>
        </div>
    );
}
