    import React, {useState} from "react";
import {makeStyles, Typography} from "@material-ui/core";

import api from '../../services/api';

import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Icon from "@material-ui/core/Icon";
import Fab from "@material-ui/core/Fab";
import wpplogo from '../../assets/img/whatsapp-logo-icone.png'
    import Snackbar from "@material-ui/core/Snackbar";
    import Button from "@material-ui/core/Button";
    import IconButton from "@material-ui/core/IconButton";
    import CloseIcon from "@material-ui/core/SvgIcon/SvgIcon";


const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '60px 0'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    title: {
        fontFamily: '\'Lexend Zetta\', sans-serif;',
        fontWeight: 'bold',
        marginBottom: '40px',
        color: '#2899f8'
    },
    text: {
        marginTop: '40px',
        fontSize: '1.2rem'
    },
    img: {
        width: '200px',
        padding: '20px'
    },
    customAppBar: {
        backgroundColor: '#f39c12'
    },
    close: {
        padding: theme.spacing(0.5),
    },
}));


export default function ContactUs() {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const classes = useStyles();

    const sendEmail = () => {
        api.post('send-email', {
            name,
            email,
            message
        })
            .then(res => {
                setName('');
                setMessage('');
                setEmail('');
                handleClick();
            });
    };

    const [open, setOpen] = React.useState(false);

    function handleClick() {
        setOpen(true);
    }

    function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    }

    return(
        <div className={classes.root} id="fale-conosco">
            <Container maxWidth="lg">
                <Typography variant="h3" align="center" className={classes.title}>
                    FALE CONOSCO
                </Typography>
                <Divider />
                <Typography variant="body1" align="center" className={classes.text}>
                    Entre em contato conosco atravás do forumulário abaixo ou clique no icone do WhatsApp
                    e entre em contato mais rapidamente.
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="outlined-name"
                            label="Nome"
                            margin="normal"
                            style={{width: '100%'}}
                            variant="outlined"
                            value={name}
                            onChange={(event => {setName(event.target.value)})}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            id="outlined-name"
                            label="Email"
                            style={{width: '100%'}}
                            margin="normal"
                            variant="outlined"
                            value={email}
                            onChange={(event => {setEmail(event.target.value)})}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField
                            id="outlined-name"
                            label="Mensagem"
                            rows={6}
                            style={{width: '100%'}}
                            multiline={true}
                            margin="normal"
                            variant="outlined"
                            value={message}
                            onChange={(event => {setMessage(event.target.value)})}
                        />
                    </Grid>
                    <Grid item xs={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Fab variant="extended" color="primary" aria-label="add" style={{margin: '20px 0'}} disabled={!(email && name && message)} onClick={sendEmail}>
                            Enviar mensagem
                            <Icon style={{marginLeft: '10px'}}>send</Icon>
                        </Fab>
                    </Grid>
                    <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                        <a href="https://wa.me/554184254578">
                            <img style={{margin: '20px 0', width: '80px', height: '80px'}} src={wpplogo} alt="whatsapplogo"/>
                        </a>
                    </Grid>
                </Grid>
            </Container>


            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={10000}
                onClose={handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">Mensagem enviada com sucesso. Em breve o respoderemos!</span>}
                action={[
                    <Button key="undo" color="secondary" size="small" onClick={handleClose}>
                        OK
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            />


        </div>
    );
}
